<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'project-create create-form'"
    v-if="getPermission('project:create')"
  >
    <template v-slot:header-title>
      <v-skeleton-loader
        v-if="pageLoading"
        class="custom-skeleton custom-skeleton-full-width"
        type="button"
      ></v-skeleton-loader>
      <h1 v-else class="form-title d-flex">
        Project For
        <span
          class="m-0 form-title-create-link pl-2"
          :class="{
            'custom-nowrap-ellipsis': !lodash.isEmpty(customerObject),
          }"
          link
        >
          <template v-if="lodash.isEmpty(customerObject)">Client Name</template>
          <template v-else>{{ customerObject.display_name }}</template>
          <v-icon
            link
            large
            color="cyan"
            class="mx-2"
            v-if="lodash.isEmpty(customerObject) || isDuplicateMode"
            >mdi-plus-circle-outline</v-icon
          >
        </span>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="onSubmit"
        color="cyan"
      >
        Save Project
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="projectForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="onSubmit"
      >
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <div class="p-5 pt-0">
            <v-row>
              <v-col cols="7" class="pb-0">
                <v-container fluid>
                  <div>
                    <label class="font-weight-700 font-size-16"
                      >Project Name</label
                    >
                    <v-text-field
                      v-model.trim="projectCreate.name"
                      dense
                      filled
                      color="cyan"
                      label="Name"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      solo
                      flat
                      :rules="[
                        validateRules.required(
                          projectCreate.name,
                          'Project Name'
                        ),
                        validateRules.minLength(
                          projectCreate.name,
                          'Project Name',
                          1
                        ),
                        validateRules.maxLength(
                          projectCreate.name,
                          'Project Name',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </div>
                  <div class="width-50">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton custom-card-heading"
                      type="card-heading, text@3"
                    ></v-skeleton-loader>
                    <template v-else>
                      <label class="font-weight-700 font-size-16"
                        >Contact details</label
                      >
                      <div v-if="lodash.isEmpty(contactPersonObject) === false">
                        <p
                          v-if="contactPersonObject.display_name"
                          class="mb-0 font-size-16"
                        >
                          <template
                            v-if="changeAttention || !projectCreate.attention"
                          >
                            <v-text-field
                              v-model.trim="projectCreate.attention"
                              :rules="[
                                validateRules.required(
                                  projectCreate.attention,
                                  'Attention'
                                ),
                                validateRules.minLength(
                                  projectCreate.attention,
                                  'Attention',
                                  1
                                ),
                                validateRules.maxLength(
                                  projectCreate.attention,
                                  'Attention',
                                  100
                                ),
                              ]"
                              dense
                              filled
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              color="cyan"
                              label="Attention"
                              solo
                              flat
                              hide-details
                              @keydown.esc="changeAttention = false"
                              @keydown.enter="changeAttention = false"
                              @keydown.tab="changeAttention = false"
                              v-on:change="changeAttention = false"
                            ></v-text-field>
                          </template>
                          <template v-else>
                            <div class="d-flex">
                              <p class="mb-0 text-ellipsis width-300px">
                                {{ projectCreate.attention }}
                              </p>
                              <v-btn
                                text
                                small
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                class="custom-bold-button"
                                v-on:click="changeAttention = true"
                                color="cyan"
                              >
                                Change
                              </v-btn>
                            </div>
                          </template>
                        </p>
                        <p
                          v-if="contactPersonObject.primary_phone"
                          class="mb-0 font-size-16 custom-nowrap-ellipsis"
                        >
                          {{ contactPersonObject.primary_phone }}
                        </p>
                        <p
                          v-if="contactPersonObject.primary_email"
                          class="mb-0 font-size-16 custom-nowrap-ellipsis"
                        >
                          {{ contactPersonObject.primary_email }}
                        </p>
                      </div>
                    </template>
                  </div>
                </v-container>
              </v-col>
              <v-col cols="5" class="pb-0">
                <v-container fluid>
                  <table class="width-100">
                    <tr>
                      <td colspan="3" class="font-weight-700 font-size-16 pb-2">
                        Project details
                      </td>
                    </tr>
                    <tr>
                      <td class="font-size-16 pb-2" width="200">
                        Project number
                      </td>
                      <td
                        class="font-weight-700 font-size-16 pb-2"
                        width="150"
                        :colspan="isUpdateMode ? 2 : 1"
                      >
                        <v-skeleton-loader
                          v-if="pageLoading"
                          class="custom-skeleton"
                          type="text"
                        ></v-skeleton-loader>
                        <template v-else>{{ projectCreate.barcode }}</template>
                      </td>
                      <td v-if="!isUpdateMode" class="font-size-16 pb-2">
                        <v-btn
                          text
                          small
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          class="mx-2 custom-bold-button"
                          v-on:click="barcodeDialog = true"
                          color="cyan"
                        >
                          Change
                        </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-size-16 pb-2" width="200">Reference #</td>
                      <td
                        colspan="2"
                        class="pb-4"
                        content="Reference #"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-text-field
                          dense
                          color="cyan"
                          filled
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          v-model.trim="projectCreate.reference"
                          label="Reference #"
                          :rules="[
                            validateRules.minLength(
                              projectCreate.reference,
                              'Reference',
                              1
                            ),
                            validateRules.maxLength(
                              projectCreate.reference,
                              'Reference',
                              100
                            ),
                          ]"
                          solo
                          flat
                          hide-details
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-size-16 pb-2" width="200">Start Date</td>
                      <td
                        colspan="2"
                        class="pb-4"
                        content="Start Date"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <DatePicker
                          :pageLoading="pageLoading"
                          solo
                          :placeholder="'Start Date'"
                          v-on:update:date-picker="setStartDate"
                        ></DatePicker>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-size-16 pb-2" width="200">Deadline</td>
                      <td
                        colspan="2"
                        class="pb-4"
                        content="Deadline"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <DatePicker
                          :defaultDate="defaultDeadline"
                          :pageLoading="pageLoading"
                          solo
                          :placeholder="'Deadline'"
                          v-on:update:date-picker="setDeadline"
                        ></DatePicker>
                      </td>
                    </tr>
                  </table>
                </v-container>
              </v-col>
              <v-col cols="12">
                <table class="width-100">
                  <tr>
                    <td width="25%" class="px-2" content="Members" v-tippy>
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        :items="memberList"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model.trim="projectCreate.members"
                        label="Members"
                        solo
                        flat
                        item-color="cyan"
                        item-text="full_name"
                        item-value="id"
                        hide-details
                        multiple
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0">
                            <span>{{ item.full_name }}</span>
                          </v-chip>
                          <span
                            v-if="index === 1"
                            class="grey--text font-size-12"
                          >
                            (+{{ projectCreate.members.length - 1 }} others)
                          </span>
                        </template>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Member Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                    <td width="25%" class="px-2" content="Billing Type" v-tippy>
                      <v-select
                        dense
                        color="cyan"
                        filled
                        :items="billingTypeList"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model.trim="projectCreate.billing_type"
                        label="Billing Type"
                        :rules="[
                          validateRules.required(
                            projectCreate.billing_type,
                            'Billing Type'
                          ),
                        ]"
                        solo
                        flat
                        item-color="cyan"
                        hide-details
                      ></v-select>
                    </td>
                    <td
                      width="25%"
                      class="px-2"
                      :content="billingTypeLabel"
                      v-tippy
                    >
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model.trim="projectCreate.rate_per_hour"
                        :label="billingTypeLabel"
                        :rules="[
                          validateRules.minLength(
                            projectCreate.rate_per_hour,
                            billingTypeLabel,
                            1
                          ),
                          validateRules.maxLength(
                            projectCreate.rate_per_hour,
                            billingTypeLabel,
                            100
                          ),
                        ]"
                        solo
                        flat
                        type="number"
                        min="0"
                        hide-details
                        v-on:keypress="
                          limitDecimal($event, projectCreate.rate_per_hour)
                        "
                      ></v-text-field>
                    </td>
                    <td
                      width="25%"
                      class="px-2"
                      content="Estimated Hours"
                      v-tippy
                    >
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model.trim="projectCreate.estimated_hour"
                        label="Estimated Hours"
                        :rules="[
                          validateRules.minLength(
                            projectCreate.estimated_hour,
                            'Estimated Hours',
                            1
                          ),
                          validateRules.maxLength(
                            projectCreate.estimated_hour,
                            'Estimated Hours',
                            100
                          ),
                        ]"
                        solo
                        flat
                        type="number"
                        min="0"
                        hide-details
                        v-on:keypress="
                          limitDecimal($event, projectCreate.estimated_hour)
                        "
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-2 py-5" colspan="4">
                      <editor v-model="projectCreate.description" />
                    </td>
                  </tr>
                </table>
              </v-col>
            </v-row>
          </div>
        </perfect-scrollbar>
      </v-form>
      <BarcodeSetting
        endpoint="projects"
        :barcodeDialog="barcodeDialog"
        :dialogWidth="600"
        :barcodeSetting="barcodeSetting"
        v-on:close:barcode-dialog="barcodeDialog = false"
      ></BarcodeSetting>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET,
  POST,
  QUERY,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "project-create",
  title: "Create Project",
  data() {
    return {
      duplicate: 0,
      pageLoading: true,
      isUpdateMode: false,
      barcodeDialog: false,
      isDuplicateMode: false,
      changeAttention: false,
      customerObject: new Object(),
      projectCreate: new Object({
        name: null,
        quotation: null,
        customer: null,
        attention: null,
        barcode: null,
        reference: null,
        soled_job_no: null,
        billing_type: 1,
        members: [],
        rate_per_hour: null,
        estimated_hour: null,
        started_at: null,
        deadline: null,
        description: null,
      }),
      contactPersonObject: new Object(),
      barcodeSetting: new Object(),
      customer: null,
      memberList: [],
      billingTypeList: [
        { text: "Fixed Rate", value: 1 },
        { text: "Project Hours", value: 2 },
      ],
    };
  },
  components: {
    CreateUpdateTemplate,
    DatePicker,
    BarcodeSetting,
    editor: TinyMCE,
  },
  watch: {
    formValid(param) {
      if (param) {
        this.$store.dispatch(CLEAR_ERROR, {});
      }
    },
    duplicate(param) {
      if (param > 0) {
        this.getProject();
      }
    },
  },
  methods: {
    getProject() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "projects/" + _this.duplicate,
        })
        .then(({ data }) => {
          _this.projectCreate.name = data.name;
          _this.projectCreate.customer = data.customer.customer;
          _this.projectCreate.attention = data.attention;
          _this.projectCreate.reference = data.reference;
          _this.projectCreate.billing_type = data.billing_type;
          _this.projectCreate.rate_per_hour = data.rate_per_hour || null;
          _this.projectCreate.estimated_hour = data.estimated_hour || null;
          _this.projectCreate.started_at = data.started_at;
          _this.projectCreate.deadline = data.deadline;
          _this.projectCreate.description = data.description;

          if (data.members && data.members.length > 0) {
            _this.projectCreate.members = data.members.map((row) => {
              return row.user.id;
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    setStartDate(param) {
      this.projectCreate.started_at = param;
    },
    setDeadline(param) {
      this.projectCreate.deadline = param;
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "project/options",
          data: { customer: _this.customer },
        })
        .then(({ data }) => {
          _this.projectCreate.barcode = data.barcode;
          _this.barcodeSetting = data.option;
          _this.contactPersonObject = data.contact_person;
          _this.projectCreate.attention = data.contact_person.display_name;
          _this.customerObject = data.customer;
          _this.memberList = data.members;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    onSubmit() {
      const _this = this;
      try {
        if (!_this.$refs.projectForm.validate()) {
          return false;
        }

        _this.$store.dispatch(CLEAR_ERROR, {});

        _this.formLoading = true;

        _this.$store
          .dispatch(POST, {
            url: "projects",
            data: _this.projectCreate,
          })
          .then(({ data }) => {
            _this.$router.push(
              _this.getDefaultRoute("project.detail", {
                params: { id: data.id },
              })
            );
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },
    getQuotation() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "quotation/" + _this.quotation })
        .then(({ data }) => {
          _this.projectCreate.quotation = data.id;
          _this.projectCreate.name = data.job_title;
          _this.projectCreate.attention = data.attention;
          _this.projectCreate.reference = data.barcode;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },
  created() {
    const _this = this;
    _this.duplicate = _this.lodash.toSafeInteger(_this.$route.query.duplicate);
    _this.customer = _this.lodash.toSafeInteger(_this.$route.query.customer);
    _this.quotation = _this.lodash.toSafeInteger(_this.$route.query.quotation);
    if (_this.quotation > 0) {
      _this.getQuotation();
    }
    _this.projectCreate.customer = _this.customer;
    if (_this.customer <= 0) {
      _this.$router.go(-1);
    }
  },
  mounted() {
    const _this = this;
    _this.getOptions();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Project", route: "project" },
      { title: "Create" },
    ]);
  },
  computed: {
    billingTypeLabel() {
      if (this.projectCreate.billing_type == 1) {
        return "Total Rate";
      } else if (this.projectCreate.billing_type == 2) {
        return "Rate Per Hour";
      }
      return null;
    },
    defaultDeadline() {
      let today = new Date();
      today.setDate(today.getDate() + 30);
      return today.toISOString().substr(0, 10);
    },
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    _this.routePreventDialog = true;
    PreventDialogEventBus.$on("stay-on-page", function () {
      _this.routePreventDialog = false;
      next(false);
    });
    PreventDialogEventBus.$on("leave-page", function () {
      _this.routePreventDialog = false;
      next();
    });
  },
};
</script>
